import { createAsyncThunk } from '@reduxjs/toolkit';
import { withResolvedError } from '@wix/members-area-commons-ts';

import { Interactions } from '../../../constants/interactions';
import { updateAccountData as updateAccountDataRequest } from '../../../server/account.api';
import type {
  AccountDataProps,
  FlowApi,
  MediaPlatformImage,
  PublicInfo,
  ThunkApiConfig,
} from '../../../types';
import type {
  AccountData,
  UpdateAccountDataRequest as UpdateAccountDataRequestPayload,
} from '../../../types/server';
import { ServerErrorCode } from '../../../types/server';
import { maybeUpdateAvatar } from '../../slices/member/thunk';
import {
  getPublicInfo,
  getPublicInfoWithMediaPicture,
  hasMemberAvatarURLChanged,
} from './utils';

const hasProfileInfoChanged = (
  initialPublicInfo: PublicInfo,
  updatedPublicInfo: PublicInfo,
) => {
  return (
    updatedPublicInfo.name !== initialPublicInfo.name ||
    updatedPublicInfo.title !== initialPublicInfo.title ||
    hasMemberAvatarURLChanged(initialPublicInfo, updatedPublicInfo)
  );
};

const handleAccountDataUpdateRequest = async (
  payload: UpdateAccountDataRequestPayload,
  flowAPI: FlowApi,
) => {
  const { httpClient, errorHandler } = flowAPI;

  const requestFn = () => httpClient.request(updateAccountDataRequest(payload));

  const { data } = await withResolvedError(requestFn, errorHandler, {
    errorCodesMap: {},
  });

  return data;
};

const updateAccountData = createAsyncThunk<
  AccountData,
  AccountDataProps,
  ThunkApiConfig
>(
  'accountData/update',
  async (
    { currentFields, changedPrivacyFields, currentMember },
    {
      rejectWithValue,
      dispatch,
      getState,
      extra: { flowAPI, profileInfoChangeSubject },
    },
  ) => {
    try {
      flowAPI.fedops.interactionStarted(Interactions.AccountDataUpdate);
      const state = getState();
      const initialMember = state.member;
      const initialFields = state.fields;
      const changedPrivacyFieldsIds = changedPrivacyFields.map(
        (field) => field.id,
      );

      let memberWithMaybeUpdatedAvatar = initialMember;
      let profileCardPicture: MediaPlatformImage | undefined;

      const maybeUpdateAvatarResult = await dispatch(
        maybeUpdateAvatar({ currentMember }),
      );

      if (maybeUpdateAvatar.fulfilled.match(maybeUpdateAvatarResult)) {
        const updateAvatarPayload = maybeUpdateAvatarResult.payload;
        const { avatarPhoto, mediaPlatformPicture } = updateAvatarPayload;
        memberWithMaybeUpdatedAvatar = {
          ...initialMember,
          profile: { ...initialMember.profile, photo: avatarPhoto },
        };
        profileCardPicture = mediaPlatformPicture;
      }

      const payload = {
        member: memberWithMaybeUpdatedAvatar,
        fields: currentFields,
        changedPrivacyFieldsIds,
      };

      const data = await handleAccountDataUpdateRequest(payload, flowAPI);

      if ('error' in data) {
        return rejectWithValue(data.error);
      }

      const initialProfileInfo = getPublicInfo(initialFields, initialMember);
      const currentProfileInfo = getPublicInfo(currentFields, currentMember);

      if (hasProfileInfoChanged(initialProfileInfo, currentProfileInfo)) {
        const publicInfoWithMediaPicture = getPublicInfoWithMediaPicture(
          initialProfileInfo,
          currentProfileInfo,
          profileCardPicture,
        );

        profileInfoChangeSubject?.notifyObservers(publicInfoWithMediaPicture);
      }

      flowAPI.fedops.interactionEnded(Interactions.AccountDataUpdate);

      return data;
    } catch (error) {
      return rejectWithValue(ServerErrorCode.INTERNAL);
    }
  },
);

export const accountDataThunk = { updateAccountData };
