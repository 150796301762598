export enum Experiment {
  EnableCSSOptimization = 'specs.myAccount.EnableCSSOptimization',
  EnableOOIInEditor = 'specs.membersArea.framelessEditorMyAccount',
  EnableNewSettings = 'specs.myAccount.UseNewSettings',
  ShowBlockedMembersModalEmptyState = 'specs.myAccount.ShowBlockedMembersModalEmptyState',
  ShowPrivacySettingsMessageForSiteOwners = 'specs.myAccount.ShowPrivacySettingsMessageForSiteOwners',
  EnableButtonTextSetting = 'specs.myAccount.ShowButtonTextSetting',
  EnableUrlEditNote = 'specs.myAccount.EnableUrlEditNote',
  EnableWDSPanels = 'specs.myAccount.EnableWDSPanels',
  EnableCSSIndicators = 'specs.myAccount.EnableCSSIndicators',
  EnableReactHookForm = 'specs.myAccount.EnableReactHookForm',
}
